import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import LayoutHeaderFooter from "../../components/LayoutHeaderFooter/LayoutHeaderFooter";
import FirstComponentSmall from "../../components/FirstComponentSmall/FirstComponentSmall";
import "./ToC.scss";

const ToC = (props) => {
    const {
      content,
    } = props.pageContext;

    const location_href = props.location.pathname;
    
    return (
      <div className="toc-wrapper">
        <LayoutHeaderFooter
          header_background_color={"#8C17FF"}
          SEO_title={content.SEO.title}
          SEO_description={content.SEO.description}
          SEO_keywords={content.SEO.keywords}
          SEO_location={location_href}
        >
          <FirstComponentSmall
            primary_color={"#5107FF"}
            secondary_color={"#8C17FF"}
            title={content.pageTitle}
            description={content.pageSubtitle}
          />
            <div className="content">
                <ReactMarkdown
                    rehypePlugins={[rehypeRaw]}
                    children={content.currentToC}
                />
            </div>
            <div className="archives">
              <h2>Archívum</h2>
              <ul>
              {
                content.downloadableFiles.map( (itm, idx) => { return (
                    <li key={`arch-${idx}`}><a href={`/${itm.url}`}>{itm.displayName}</a></li>
                  )
                } )
              }
              </ul>
            </div>
        </LayoutHeaderFooter>
      </div>
    );
  };
  
  export default ToC;
  